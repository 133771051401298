import React from "react"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import { Wrapper, Speaker } from "./style"
import Rodal from "rodal"
import { Stage } from "../Schedule/style"
import SVGIcon from "../../common/SVGIcon"
import { formatTime } from "../../../utils/strings"

function EventModal({ onClose, visible, event = { speakers: [] } }) {
  return (
    <DefaultWrapper>
      <Wrapper>
        <Rodal
          enterAnimation={"slideUp"}
          leaveAnimation={"fade"}
          duration={300}
          visible={visible}
          onClose={onClose}
        >
          <div className="inner-modal-wrapper">
            <div className="top">
              <Stage
                highlight={{
                  color: event.color,
                  bg: event.backgroundColor,
                }}
                className="stage"
              >
                {event.venue !== "Empty Field" ? event.venue : ""}
              </Stage>
              <div className="time-info">
                <span className="time">{formatTime(event.starting_time)}</span>
                <span className="duration">
                  <SVGIcon name="clock" width={14} />
                  {event.duration}
                </span>
              </div>
              <h1>{event.title}</h1>
              <p dangerouslySetInnerHTML={{__html: event.description}}/>
            </div>
            <div className="speakers-row">
              {event.speakers.map(({ speaker_name: name, speaker_image }) => (
                <Speaker key={name}>
                  {speaker_image && (
                    <div
                      className={`avatar ${
                        speaker_image ? speaker_image.source_url : "no-avatar"
                      }`}
                    >
                      <img
                        src={speaker_image ? speaker_image.source_url : ""}
                        alt={name}
                      />
                    </div>
                  )}

                  <span>{name}</span>
                </Speaker>
              ))}
            </div>
          </div>
        </Rodal>
      </Wrapper>
    </DefaultWrapper>
  )
}

export default EventModal
