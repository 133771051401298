import styled from "styled-components"
import { LG } from "../../../utils/constants"

import { MainH2, MainParagraph } from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  width: calc(100% - 60px);
  max-width: 984px;
  margin: 0 auto;
  padding-bottom: 61px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: ${({ theme }) => theme.neutralDark};

  @media (max-width: ${LG}) {
    margin: 65px 36px 0;
    padding-top: 42px;
  }
`

export const Title = styled(MainH2)`
  margin: 78px 0 22px;
  max-width: none;
  @media (max-width: ${LG}) {
    margin: 0 0 10px;
    line-height: 36px;
  }
`
export const Subtitle = styled(MainParagraph)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  max-width: 638px;
`

export const EventDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 41px;

  em {
    font-size: 16px;
    line-height: 24px;
    color: #b0c9cb;
    font-style: normal;

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    margin-left: 10px;
  }

  .event-details--item {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 12px;
    }
    &:first-child {
      margin: 0 42px 0 0;
    }
  }
  @media (max-width: ${LG}) {
    flex-direction: column;
    .event-details--item {
      &:first-child {
        margin: 0 0 19px 0;
      }
    }
  }
`
