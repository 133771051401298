import styled from "styled-components"
import { BREAKPOINT } from "../../../utils/constants"

export const FiltersWrapper = styled.div`
  max-width: 984px;
  margin: 56px auto;
  width: calc(100% - 54px);

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.neutralGreen};
  }

  .filters-row {
    display: flex;
    flex-direction: row;
    > *:not(:last-child) {
      margin-right: 19px;
    }
  }

  @media (max-width: ${BREAKPOINT}) {
    .filters-row {
      flex-direction: column;
      align-items: stretch;
      > *:not(:last-child) {
        margin: 0 0 17px;
      }
    }
  }
`

export const FilterItem = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 60px;
  padding: 8px 32px;
  transition: 0.25s ease background-color;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
  border: 0;
  outline: 0;
  border: 2px solid transparent;

  transition: 0.15s ease border-color;

  &.selected {
    ${({ color }) => color && `border: 2px solid ${color}`};
  }

  &:hover {
    background: ${({ backgroundColor }) =>
      backgroundColor.replace("0.1", "0.05")};
  }

  input {
    visibility: hidden;
    position: absolute;
  }

  .custom-radio {
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 8px;
    position: relative;
    margin-right: 10px;
    svg {
      position: absolute;
      width: 18px;
      height: 18px;
      bottom: 1px;
      left: 1px;
      opacity: 0;
      transition: 0.15s ease opacity;
    }
  }

  input[type="radio"]:checked + .custom-radio {
    background: white;
    svg {
      opacity: 1;
    }
  }

  @media (max-width: ${BREAKPOINT}) {
    text-align: center;
    padding: 12px 13px;
    span {
      flex: 1;
      margin-left: -16px;
    }
  }
`
