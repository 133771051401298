import React from "react"
import { Wrapper, DayButton } from "./style"

function DayTabs({ days, selectedDay, onSelectDay }) {
  return (
    <Wrapper>
      <div className="btns-container">
        {days.map((day, i) => (
          <DayButton
            className={selectedDay === day && "selected"}
            onClick={() => onSelectDay(day)}
            key={i}
          >
            {day}
          </DayButton>
        ))}
      </div>
    </Wrapper>
  )
}

export default DayTabs
