import styled from "styled-components"
import { Wrapper as ConsortiumModalWrapper } from "../../Consortium/Modal/style"
import { Speaker as CardSpeaker } from "../Schedule/style"

export const Wrapper = styled(ConsortiumModalWrapper)`
  color: ${({ theme }) => theme.neutralDark};
  .rodal-dialog {
    padding: 60px 96px 91px;
  }
  .inner-modal-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .stage {
    margin-bottom: 80px;
    display: inline-block;
  }
  .time-info {
    opacity: 0.4;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;

    .time {
      font-size: 24px;
      line-height: 31px;
      margin-right: 20px;
    }
    .duration {
      font-size: 14px;
      line-height: 21px;
      svg {
        margin-right: 7.5px;
        vertical-align: -3px;
      }
    }
  }
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    max-width: 536px;
    margin: 16 0 24px;
  }
  p {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 30px;
  }
  .speakers-row {
    font-weight: 300;
    font-size: 24px;
    line-height: 31px;
  }
`

export const Speaker = styled(CardSpeaker)`
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 16px;
  }
  margin-bottom: 16px;
`
