import React, { useState, useMemo } from "react"
import * as R from "ramda"
import DayTabs from "../DayTabs"
import EventGrid from "../EventGrid"
import EventModal from "../EventModal"
import { ScheduleWrapper } from "./style"
import Filters from "../Filters"
import { useStaticQuery, graphql } from "gatsby"

import { styleTheme } from "../../../utils"

const mapIndexed = R.addIndex(R.map)

const getPlaceStyles = index => {
  const highlights = [
    { color: styleTheme.lightGreen, backgroundColor: "rgba(0, 176, 138, 0.1)" },
    { color: styleTheme.orange, backgroundColor: "rgba(244, 144, 77, 0.1)" },
    { color: styleTheme.pink, backgroundColor: "rgba(243, 139, 151, 0.1)" },
  ]

  return highlights[index] ? highlights[index] : highlights[0]
}

function Schedule() {
  const {
    allWordpressWpSpeakers: { edges: speakersEdges },
    wordpressPage: agendaPage
  } = useStaticQuery(graphql`
    query AgendaQuery {
      allWordpressWpSpeakers {
        edges {
          node {
            acf {
              speaker_name
              speaker_text
              speaker_image {
                source_url
              }
            }
            wordpress_id
          }
        }
      }
      wordpressPage(template: {eq: "page-agenda.php"}) {
        acf {
          agenda {
            agendas_days {
              date
              keynotes {
                duration
                starting_time
                title
                description
                venue
                speakers {
                  speaker {
                    wordpress_id
                    post_title
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  let places = {};

  const parseEvents = R.map(
    R.pipe(
      // Fill speakers data
      ({ speakers, ...event }) => ({
        ...event,
        speakers: R.pipe(
          R.defaultTo([]),
          R.map(R.prop("speaker")),
          R.flatten,
          R.map(({ wordpress_id }) =>
            speakersEdges.find(
              R.pathEq(["node", "wordpress_id"], wordpress_id)
            )
          ),
          R.map(R.path(["node", "acf"]))
        )(speakers),
      }),
      // Insert highlight colors
      event => {
        if (!places[event.venue]) {
          places[event.venue] = getPlaceStyles(Object.keys(places).length);
        }
        const { color, backgroundColor } = places[event.venue];
        return { ...event, color, backgroundColor }
      }
    )
  );

  const days = useMemo(() => {
    return R.pipe(
      R.path(["acf", "agenda", "agendas_days"]),
      R.map(R.prop("date"))
    )(agendaPage)
  }, [agendaPage])

  const [modal, setModal] = useState({
    opened: false,
    event: undefined,
  })

  const [selectedDay, setSelectedDay] = useState(days[0])
  const [selectedPlace, setSelectedPlace] = useState(null)

  const filteredEvents = useMemo(() => {
    return R.pipe(
      R.pipe(
        R.path(["acf", "agenda", "agendas_days"]),
        R.find(R.propEq("date", selectedDay)),
        R.propOr([], "keynotes"),
        parseEvents
      ),
      R.when(
        R.always(selectedPlace),
        R.filter(R.propEq("venue", selectedPlace))
      )
    )(agendaPage)
  }, [agendaPage, selectedDay, selectedPlace])

  return (
    <ScheduleWrapper>
      <DayTabs
        days={days}
        selectedDay={selectedDay}
        onSelectDay={setSelectedDay}
      />
      <Filters
        places={places}
        selectedPlace={selectedPlace}
        onSelectPlace={place => {
          setSelectedPlace(selectedPlace === place ? null : place)
        }}
        onClearFilters={() => setSelectedPlace(null)}
      />
      <EventGrid
        events={filteredEvents}
        onOpenEventDetails={event =>
          setModal({
            opened: true,
            event,
          })
        }
      />
      <EventModal
        event={modal.event}
        visible={modal.opened}
        onClose={() =>
          setModal({
            visible: false,
            event: undefined,
          })
        }
      />
    </ScheduleWrapper>
  )
}

export default Schedule
