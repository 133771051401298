import React, {useMemo} from "react"
import * as R from "ramda"
import parse from "date-fns/parse"
import EventCard from "../EventCard"
import { GridWrapper } from "./style"
import BreakEventCard from "../BreakEventCard"
import PaddingCard from "../PaddingCard"



function EventGrid({ onOpenEventDetails, events: rawEvents }) {
  const events = useMemo(() => {
    const groupedByTime = R.pipe(R.groupBy(R.prop("starting_time")), R.values)(rawEvents)

    groupedByTime.forEach((group, i) => {
      if (group.length === 2) {
        const { time } = group[0]
        groupedByTime[i][0].size = "sm"
        groupedByTime[i][1].size = "sm"
        groupedByTime[i].push({
          is_padding: true,
          id: group[1].id + "-padding",
          time
        })
      }
      if (group.length === 3) {
        groupedByTime[i][0].size = "sm"
        groupedByTime[i][1].size = "sm"
        groupedByTime[i][2].size = "sm"
      }
    })

    return R.pipe(
      R.flatten,
      R.sortWith([
        R.ascend(event => parse(`${event.starting_time}`.toUpperCase(), 'h:mm aa', new Date()))
      ])
    )(groupedByTime)
  }, [rawEvents])

  return (
    <GridWrapper>
      {events.map((event, i) => {
        if (event.is_break) {
          return <BreakEventCard key={i} event={event} />
        }
        if (event.is_padding) {
          return <PaddingCard key={i} />
        }
        return (
          <EventCard
            key={i}
            event={event}
            onViewDetails={() => onOpenEventDetails(event)}
            size={event.size}
          />
        )
      })}
    </GridWrapper>
  )
}

export default EventGrid
