import React, { useState, Fragment } from "react"
import { Wrapper, Title, Subtitle, EventDetails } from "./style"
import SVGIcon from "../../common/SVGIcon"
import {
  PrimaryBtn,
  SecondaryButtonLink,
  PrimaryBtnExternal
} from "../../../utils/GlobalComponents"
import { styleTheme } from "../../../utils"

import { graphql, useStaticQuery } from "gatsby"

import RegisterModal from "../../RegisterModal/RegisterModal"

import styled from "styled-components"

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 24px;

    @media screen and (min-width: 1024px) {
      width: auto;
      margin: 0 32px 0 0;
    }
  }
`

const AgendaHero = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => {
    setModalOpen(!modalOpen)
  }

  const {
    newAgendaPage: {
      acf: {
        event_information: {
          description,
          event_venue,
          event_date,
          title,
          event_program,
        },
      },
    },
  } = useStaticQuery(graphql`
    query AgendaHero {
      newAgendaPage: wordpressPage(
        id: { eq: "8648f320-62b2-58ac-8d22-5779f42add57" }
      ) {
        id
        acf {
          event_information {
            description
            event_venue
            event_date
            title
            event_program {
              source_url
            }
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      <RegisterModal visible={modalOpen} onClose={() => handleModalOpen()} />
      <Wrapper>
        <Title>{title}</Title>
        {description && (
          <Subtitle dangerouslySetInnerHTML={{ __html: description }} />
        )}

        <EventDetails>
          {event_date && (
            <div className="event-details--item">
              <SVGIcon width={21} fill={styleTheme.pink} name="calendar" />
              {event_date}
            </div>
          )}

          {event_venue && (
            <div className="event-details--item">
              <SVGIcon width={16} fill={styleTheme.pink} name="location" />
              {event_venue}
              <em>Space is limited!</em>
            </div>
          )}
        </EventDetails>

        <ButtonsWrapper>
          <PrimaryBtnExternal
            as="button"
            // onClick={() => handleModalOpen(true)}
            href="https://lyme-mind-2022.open-exchange.net/welcome"
            primary={"A"}
          >
            <span>Register Now</span>
          </PrimaryBtnExternal>

          {event_program && (
            <SecondaryButtonLink
              href={event_program.source_url}
              secondary={"A"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>View Event Program</span>
            </SecondaryButtonLink>
          )}
        </ButtonsWrapper>
      </Wrapper>
    </Fragment>
  )
}

export default AgendaHero
