import styled from "styled-components"
import { BREAKPOINT } from "../../../utils/constants"

export const EventCardWrapper = styled.div`
  margin: 8px 4.5px;
  color: ${({ theme }) => theme.neutralDark};
  background: white;
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 231px;

  box-shadow: ${props => props.theme.boxShadow};
  transition: 0.25s ease all;
  border-radius: 8px;
  transform: translateY(0px);

  &[data-aos^="fade"][data-aos^="fade"].aos-animate {
    transition: 0.25s ease all;
    &:hover {
      /* transition: 0.25s ease-out all; */
      box-shadow: 0 0 0 transparent;
      transform: translateY(-4px);
      z-index: 0;

      &:after {
        box-shadow: 1px 28px 60px rgba(243, 139, 151, 0.27);
      }
    }
  }

  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 0 0 transparent;
    transition: 0.25s ease-in box-shadow;
    z-index: -1;
  }

  .stage {
    margin-left: auto;
  }
  .bottom {
    padding: 24px;
    position: relative;
    flex: 1;
    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 4px;
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* Two-line ellipsis */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .stage {
      display: inline-block;
      margin-bottom: 8px;
    }
  }

  .speakers-row {
    flex-direction: column;
    margin-bottom: 24px;
    .speaker:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .header {
    justify-content: space-between;
  }

  .header .stage {
    display: none;
  }

  @media (min-width: ${BREAKPOINT}) {
    &.size-lg {
      .header .stage {
        display: initial;
      }
      .speakers-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 0;
        .speaker {
          margin-bottom: 0;
        }
      }
      .header {
        justify-content: flex-start;
      }
      .bottom .stage {
        display: none;
      }
      .bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    &.size-sm {
      width: auto;
      flex: 1;
      .bottom {
        h3 {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          margin: 8px 0;
        }
        p {
          display: none;
        }
        .stage {
          margin-bottom: 0;
        }
      }
    }
  }
`

export const Header = styled.div`
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid rgba(176, 201, 203, 0.22);
  padding-top: 8px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: ${({ highlight }) => highlight.color};
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .event-time {
    font-weight: 500;
    margin-right: 18px;
  }
  .event-duration {
    svg {
      vertical-align: -4px;
      margin-right: 6px;
    }
    span {
      opacity: 0.4;
    }
  }
`

export const ViewDetailsBtn = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  background: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.neutralGreen};
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.12s ease color;
  letter-spacing: 0.05em;

  /* Make it easier to click */
  padding: 15px;
  margin: -15px;

  svg {
    margin-left: 6px;
    transition: 0.12s ease transform;
  }

  position: absolute;
  bottom: 16px;
  right: 16px;

  .size-lg & {
    bottom: 24px;
  }

  &:hover {
    color: ${({ theme }) => theme.neutralDark};
    svg {
      transform: translateX(5px);
    }
  }
`
