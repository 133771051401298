import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/Agenda/Hero"
import Schedule from "../components/Agenda/Schedule"

const EventSchedulePage = () => {
  return (
    <Layout>
      <SEO title="LymeMIND 2021 Agenda" />
      <Hero />
      <Schedule />
    </Layout>
  )
}

export default EventSchedulePage
