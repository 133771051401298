import styled from "styled-components"
import GridPaddingBg from "../../../images/grid-padding-bg.svg"
import { LG } from "../../../utils/constants"

const PaddingCard = styled.div`
  flex: 1;
  background: url(${GridPaddingBg}) center center no-repeat;
  background-size: cover;
  margin: 8px 4.5px;
  border-radius: 8px;
  @media (max-width: ${LG}) {
    display: none;
  }
`

export default PaddingCard
