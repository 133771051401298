import React from "react"
import { EventCardWrapper, ViewDetailsBtn, Header } from "./style"
import SVGIcon from "../../common/SVGIcon"
import { Stage, Speaker } from "../Schedule/style"
import { formatTime } from "../../../utils/strings"
import { styleTheme } from "../../../utils"
import defaultAvatar from '../../../images/default-avatar.svg'

function EventCard({ size = "lg", onViewDetails, event }) {
  const highlight = {
    color: event.color,
    bg: event.backgroundColor,
  }
  return (
    <EventCardWrapper
      className={`size-${size}`}
      highlight={highlight}
      data-aos="fade-up"
      data-aos-duration="700"
    >
      <Header className="header" highlight={highlight}>
        <span className="event-time">{formatTime(event.starting_time)}</span>
        <span className="event-duration">
          <SVGIcon name="clock" fill={styleTheme.neutralGreen} width={16} />
          <span>{event.duration}</span>
        </span>
        <Stage className="stage" highlight={highlight}>
          {event.venue !== "Empty Field" ? event.venue : ""}
        </Stage>
      </Header>

      <div className="bottom">
        <div className="bottom-top">
          <Stage highlight={highlight} className="stage">
            {event.venue !== "Empty Field" ? event.venue : ""}
          </Stage>
          <h3>{event.title}</h3>
          <p dangerouslySetInnerHTML={{__html: event.description}}/>
        </div>

        <ul className="speakers-row">
          {event.speakers
            .slice(0, 4)
            .map(({ speaker_name: name, speaker_image }) => (
              <Speaker className="speaker" key={name}>
                <div
                  className={`avatar ${
                    speaker_image && speaker_image.source_url ? "" : "no-avatar"
                    }`}
                >
                  <img src={(speaker_image && speaker_image.source_url) || defaultAvatar} alt={name} />
                </div>
                <span>{name}</span>
              </Speaker>
            ))}
        </ul>
        <ViewDetailsBtn onClick={onViewDetails} className="view-details-btn">
          View details <SVGIcon name="smallArrowRight" width={16} />
        </ViewDetailsBtn>
      </div>
    </EventCardWrapper>
  )
}

export default EventCard
