import styled from "styled-components"

export const EventCardWrapper = styled.div`
  background: ${({ theme }) => theme.lightOrangeGradient};
  margin: 8px 4.5px;
  width: 100%;
  border-radius: 8px;
  color: ${({ theme }) => theme.neutralDark};

  .bottom {
    padding: 24px;
    padding-top: 0;
    position: relative;
    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 4px;
    }
  }

  .header {
    justify-content: flex-start;
  }
`

export const Header = styled.div`
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  position: relative;

  .event-time {
    font-weight: 500;
    margin-right: 18px;
  }
  .event-duration {
    span {
      opacity: 0.4;
    }
    svg {
      vertical-align: -4px;
      margin-right: 6px;
    }
  }
`
