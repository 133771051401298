import React, { useMemo } from "react"
import * as R from 'ramda';
import { FiltersWrapper, FilterItem } from "./style"
import SVGIcon from "../../common/SVGIcon"

function Filters({ places: rawPlaces, selectedPlace, onSelectPlace, onClearFilters }) {
  const places = useMemo(() => {
    return R.pipe(
      Object.entries,
      R.filter(place => place[0] !== "undefined"),
      R.map(place => ({ name: place[0], ...place[1] }))
    )(rawPlaces)
  }, [rawPlaces])

  if (places.length <= 1) return null;

  return (
    <FiltersWrapper>
      <p>Filter by location:</p>
      <div className="filters-row">
        {places.map(({ name, color, backgroundColor }) => {
          if (name !== "Empty Field" && name !== "null") {
            return (
              <FilterItem
                color={color}
                backgroundColor={backgroundColor}
                className={name === selectedPlace && "selected"}
                htmlFor={`filter-${name}`}
                key={`filter-${name}`}
              >
                {/* eslint-disable-next-line */}
                <input
                  name="location"
                  id={`filter-${name}`}
                  value={name}
                  type="radio"
                  checked={selectedPlace === name}
                  onChange={() => onSelectPlace(name)}
                  onClick={() => selectedPlace === name && onClearFilters()}
                />
                <div className="custom-radio">
                  <SVGIcon stroke={color} name="tick"></SVGIcon>
                </div>
                <span>{name}</span>
              </FilterItem>
            )
          } else {
            return null
          }
        })}
        {selectedPlace && (
          <FilterItem
            as="button"
            color={"rgba(0, 81, 99, 0.46)"}
            backgroundColor={"#FAFAFA"}
            htmlFor={`filter-clear`}
            key={`filter-clear`}
            onClick={onClearFilters}
          >
            <span>CLEAR FILTERS</span>
          </FilterItem>
        )}
      </div>
    </FiltersWrapper>
  )
}

export default Filters
