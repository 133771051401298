import React from "react"
import { EventCardWrapper, Header } from "./style"
import SVGIcon from "../../common/SVGIcon"
import { styleTheme } from "../../../utils"

function BreakEventCard({ event }) {
  return (
    <EventCardWrapper>
      <Header className="header">
        <span className="event-time">{event.starting_time}</span>
        <span className="event-duration">
          <SVGIcon fill={styleTheme.orange} name="clock" width={16} />
          <span>{event.duration}</span>
        </span>
      </Header>

      <div className="bottom">
        <h3>{event.title}</h3>
      </div>
    </EventCardWrapper>
  )
}

export default BreakEventCard
