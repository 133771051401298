import styled from "styled-components"
import React from 'react';

export const ScheduleWrapper = styled.section``

const StageWrapper = styled.span`
  padding: 3px 8px;
  color: ${({ highlight }) => highlight.color};
  background: ${({ highlight }) => highlight.bg};
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: right;
  letter-spacing: 0.05em;
`

export const Stage = ({children, ...props}) => children ? <StageWrapper {...props}>{children}</StageWrapper> : null;

export const Speaker = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: 16px;

  .avatar {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border: 1px solid rgba(176, 201, 203, 0.22);
    margin-right: 8px;
    border-radius: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.no-avatar {
      background: rgba(176, 201, 203, 0.22);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 0;

      img {
        width: 13px;
        height: 15px;
        object-fit: contain;
      }
    }
  }
`
