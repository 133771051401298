import styled from "styled-components"
import { BREAKPOINT } from "../../../utils/constants"

export const Wrapper = styled.div`
  width: 100%;
  background: white;
  height: 148px;
  box-shadow: 1px 28px 60px rgba(0, 43, 52, 0.08);

  .btns-container {
    margin: 0 auto;
    max-width: 984px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  @media (max-width: ${BREAKPOINT}) {
    height: 74px;
  }
`

export const DayButton = styled.button`
  position: relative;
  cursor: pointer;
  background: none;
  height: 100%;
  outline: 0;
  border: 0;
  padding: 0 22px;
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  transition: 0.25s ease opacity;
  opacity: 0.4;
  color: ${({ theme }) => theme.neutralDark};

  &:not(:last-child) {
    margin-right: 56px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 4px;
    background: ${props => props.theme.orangeGradient};
    transition: 0.25s ease all;
  }

  &.selected {
    opacity: 1;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      background: ${props => props.theme.orangeGradient};
    }
  }

  @media (max-width: ${BREAKPOINT}) {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    flex: 1;

    &:not(:last-child) {
      margin: 0;
    }
  }
`
