import styled from "styled-components"

export const GridWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 994px;
  margin: 56px auto;
  width: calc(100% - 54px);
`
